<template lang="pug">
	.d-flex.flex-wrap.justify-content-between.integration
		//- YANDEX
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			:class="{'success': hasYAPixel && isYAConnected, 'error': hasYAPixel && !isYAConnected}"
			@click="$bvModal.show('integration-form-ya')"
		)
			span.b4.integration__text(v-if='hasYAPixel' :class="isYAConnected ? 'text-success' : 'text-error'") {{ isYAConnected ? $t('forms.connected') : $t('errors.metricId') }}
			img(:src="isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'")
			span.btn.btn-primary.integration__plus-icon(v-if="!ya.ymId")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-ya'" :icon="isMobile ? '' : isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'"  :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.yaCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-if="error" v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="ya.ymId"
					:label="$t('pixel.counter_id')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorYA"
					@blur='isYAValid'
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;${$t('tutorials.yandexMetrica')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/yandeks-metrika'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !ya.ymId || errorYA}" @click='saveYA')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getYMId()" @click='openModalRemove("YA")')

		//- VCONTACTE
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			:class="{'success': hasVKPixel && isVKConnected, 'error': hasVKPixel && !isVKConnected}"
			@click="$bvModal.show('integration-form-vk')"
		)
			span.b4.integration__text(v-if='hasVKPixel' :class="isVKConnected ? 'text-success' : 'text-error'") {{ isVKConnected? $t('forms.connected') : $t('errors.pixelId') }}
			img(src="/assets/img/icons/vk-pixel.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="!vkPixel.id")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-vk'" :icon="isMobile ? '' : '/assets/img/icons/vk-pixel.svg'" :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : '/assets/img/icons/vk-pixel.svg'")
				.d-flex.align-items-center.mb-3
					p.b3.mr-2.mb-0(v-if="!error" v-html="$t('forms.vkPixelCounter')")
					svg.cursor-pointer(:id="!isMobile ? `tooltip-vkPixel` : ''" @click="isMobile ? $bvModal.show(`tooltip-vkPixel`) : ''" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						circle(cx="12" cy="12" r="12" :fill="isDarkColor ? '#333' : '#F7F7F8'")
						path(d="M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z" fill="#52A7F9")

				my-input.mb-4(
					v-model.trim="vkPixel.id"
					prefix=''
					:label="$t('pixel.vkCodeTitle')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorVK"
					@blur='isVKValid()'
				)
				p.b3.mb-4.position-relative.integration__warning(v-html="$t('pixel.vkWarning')")

				.d-flex.justify-content-between.mb-4.integration__card-link
					.d-flex.flex-column
						p.b2.text-grey.mb-1 {{ $t('h1.addressPaymentPage') }}
						a.b1.text-break.text-primary(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
					i.text-primary.fontello.fontello-icon-copy.cursor-pointer.mb-auto.mt-1.mt-md-auto.mb-md-1(@click="copy(paymentLinkDefault)" v-clipboard:copy="paymentLinkDefault")

				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;${$t('tutorials.VKpixel')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/vk-piksel'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !vkPixel.id || errorVK}" @click='saveVK')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getVKPixelId()" @click='openModalRemove("VK")')

				modal-tooltip(
					:id="`tooltip-vkPixel`"
					:classTooltip="'vkPixel-tooltip'"
					:title="''"
					:text="`${$t('forms.vkPixelCounterDesc')}`"
					:textMobile="`${$t('forms.vkPixelCounterDesc')}`"
				)

		//- GOOGLE
		.d-flex.justify-content-between.align-items-center.cursor-pointer.mb-3.integration__card(
			:class="{'success': hasGAPixel && isGAConnected, 'error': hasGAPixel && !isGAConnected}"
			@click="$bvModal.show('integration-form-ga')"
		)

			span.b4.integration__text(v-if='hasGAPixel' :class="isGAConnected ? 'text-success' : 'text-error'") {{ isGAConnected ? $t('forms.connected') : $t('errors.metricId') }}
			img(src="/assets/img/icons/ga.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="item && !item.getGAId()")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-ga'" :icon="isMobile ? '' : '/assets/img/icons/ga.svg'" :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : '/assets/img/icons/ga.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.gaCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-if="error" v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="ga.gaId"
					:label="$t('pixel.counter_id')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorGA"
					@blur='isGAValid()'
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;Google Analytics`" :href="'https://docs.paywall.pw/rus/obshee/integracii/google-analytics'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !ga.gaId || errorGA}" @click='saveGA')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getGAId()" @click='openModalRemove("GA")')

		//- FACEBOOK
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			@click="$bvModal.show('integration-form-facebook')"
			:class="{'success': hasFBPixel && isFBConnected, 'error': hasFBPixel && !isFBConnected}"
		)
			span.b4.integration__text(v-if='hasFBPixel' :class="isFBConnected ? 'text-success' : 'text-error'") {{ isFBConnected ? $t('forms.connected') : $t('errors.pixelId') }}
			img(:src="isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'")
			span.btn.btn-primary.integration__plus-icon(v-if="!hasFBPixel")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-facebook'" :icon="isMobile ? '' : isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'"  :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'")
				p.b3.mb-3(v-if="error" v-html="$t('forms.fbCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="pixel.pixelId"
					:label="'Pixel ID'"
					:icon="'icon-archive-book'"
					:errorFeedback="errorFB"
					@blur='isFBValid()'
				)
				p.b1.mb-3 {{ $t('pixel.eventValue') }}
				p.b3.text-grey.mb-4 {{ $t('pixel.assignValue') }}
				my-input.mb-4(
					v-model.trim="pixel.viewProject"
					:label="`view_project (${$t('pixel.view_project')})`"
					:icon="'icon-home-hashtag'"
					:errorFeedback="''"
				)
				my-input.mb-4(
					v-model.trim="pixel.clickPurchase"
					:label="`click_purchase (${$t('pixel.click_purchase')})`"
					:icon="'icon-wallet'"
					:errorFeedback="''"
				)
				my-input.mb-4(
					v-model.trim="pixel.purchase"
					:label="`purchase (${$t('pixel.purchase')})`"
					:icon="'icon-card-tick'"
					:errorFeedback="''"
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;Facebook`" :href="'https://docs.paywall.pw/rus/obshee/integracii/facebook-pixel'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !pixel.pixelId || errorFB}" @click='saveFB' :disabled='isPending')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="hasFBPixel" @click='openModalRemove("FB")')

		//- VCONTACTE ADS
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			:class="{'success': hasVKAds && isVKAdsConnected, 'error': hasVKAds && !isVKAdsConnected}"
			@click="$bvModal.show('integration-form-vk-ads')"
		)
			span.b4.integration__text(v-if='hasVKAds' :class="isVKAdsConnected ? 'text-success' : 'text-error'") {{ isVKAdsConnected? $t('forms.connected') : $t('errors.pixelId') }}
			img(src="/assets/img/icons/vk-ads.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="!vkAds.id")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-vk-ads'" :icon="isMobile ? '' : '/assets/img/icons/vk-ads.svg'" :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : '/assets/img/icons/vk-ads.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.vkPixelCounter')")

				my-input.mb-4(
					v-model.trim="vkAds.id"
					:type="'number'"
					prefix=''
					:label="$t('pixel.vkCodeTitle')"
					:icon="'icon-archive-book'"
					:maxlength="7"
					:errorFeedback="errorVKAds"
					@blur='isVKAdsValid()'
				)
				p.b3.mb-4.position-relative.integration__warning(v-html="$t('pixel.vkWarning')")

				.d-flex.justify-content-between.mb-4.integration__card-link
					.d-flex.flex-column
						p.b2.text-grey.mb-1 {{ $t('h1.addressPaymentPage') }}
						a.b1.text-break.text-primary(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
					i.text-primary.fontello.fontello-icon-copy.cursor-pointer.mb-auto.mt-1.mt-md-auto.mb-md-1(@click="copy(paymentLinkDefault)" v-clipboard:copy="paymentLinkDefault")

				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;${$t('tutorials.VKAds')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/vk-ads'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !vkAds.id || errorVKAds}" @click='saveVKAds')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getVKAdsId()" @click='openModalRemove("VKAds")')
		
		//- MAVE
		.d-flex.justify-content-between.align-items-center.mb-3.integration__card(
			@click="hasMave ? '' : $bvModal.show('integration-form-mave')"
			:class="{'success': hasMave && isMaveConnected, 'error': hasMave && !isMaveConnected, 'cursor-pointer': !hasMave}"
		)
			span.b4.integration__text(v-if='hasMave' :class="isMaveConnected ? 'text-success' : 'text-error'") {{ isMaveConnected ? $t('forms.connected') : $t('errors.integrationConnection') }}
			img(src="/assets/img/icons/mave.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="!hasMave")
				span.m-0.plus-primary
			i.fontello-btn.fontello-btn-red.fontello-icon-trash.integration__remove-icon(v-else @click='openModalRemove("Mave")')
			
			modal-general(:id="'integration-form-mave'" :icon="isMobile ? '' : '/assets/img/icons/mave.svg'" :title="isMobile ? $t('h1.settings') : ''" :contentClass="'integration-form-mave'")
				img.mr-auto.integration__modal-image.is-mave(:src="!isMobile ? '' : '/assets/img/icons/mave.svg'")
				p.b3.mb-4(v-html="$t('forms.maveCounter')")

				my-input.mb-4(
					v-model.trim="mave.id"
					:label="$t('pixel.integrationKey')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorMave"
					@blur='isMaveValid()'
				)

				b.text-grey.b3.mb-4.integration__info(v-html="$t('pixel.maveInfo')")

				p.b4.text-grey.mb-10(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.integration__base-link.is-mave(:text="`🤝&nbsp;${$t('tutorials.howToConnectMave')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/mave'" :icon="'no-icon btn-mave'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !mave.id || errorMave}" @click='saveMave')
						.loader(v-if='isLoading')
						span(v-else) {{ $t('forms.save') }}

		modal-remove-integration(
			:id="`remove-integration`"
			:title="$t('forms.removeIntegration')"
			:text="$t('forms.removeIntegrationDesc')"
			:item='removeItem'
			@onAction='actionRemove')
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import ModalRemoveIntegration from '@/components/Modals/RemoveIntegration';
import MyInput from '@/components/UI/MyInput';
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import { Project } from '@/models/project';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'Integration',
    components: {
        ModalGeneral,
        ModalRemoveIntegration,
        MyInput,
        PwBtnLink,
        ModalTooltip,
    },
    data: () => ({
        ga: { gaId: null },
        pixel: {
            pixelId: null,
            viewProject: null,
            clickPurchase: null,
            purchase: null,
        },
        ya: { ymId: null },
        vkAds: { id: null },
        vkPixel: { id: null },
        mave: { id: null },
        hasFBPixel: false,
        editIntegration: false,
        removeItem: null,
        success: false,
        error: false,
        errorVKAds: '',
        errorVK: '',
        errorYA: '',
        errorGA: '',
        errorFB: '',
        errorMave: '',
        hasMave: null,
        isLoading: false,
    }),
    created() {
        if (this.item != null) {
            this.setForm(this.item);
            this.hasMave = this.item.isMave;
        }

        this.$on('removeYA', this.removeYA);
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            isPending: 'project/isPending',
        }),
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (this.link != null) return this.link;
            if (!this.item) return '';

            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
        hasGAPixel() {
            return this.item && this.item.getGAId();
        },
        isGAConnected() {
            if (!this.item) return false;
            return Project.isGAValid(this.item.getGAId());
        },
        isFBConnected() {
            if (!this.item) return false;
            return Project.isFBValid(this.item.getPixelId());
        },
        hasVKAds() {
            return this.item && this.item.getVKAdsId();
        },
        hasVKPixel() {
            return this.item && this.item.getVKPixelId();
        },
        isVKAdsConnected() {
            if (!this.item) return false;
            return Project.isVKAdsValid(this.item.getVKAdsId());
        },
        isVKConnected() {
            if (!this.item) return false;
            return Project.isVKPixelValid(this.item.getVKPixelId());
        },
        hasYAPixel() {
            return this.item && this.item.getYMId();
        },
        isYAConnected() {
            if (!this.item) return false;
            return Project.isYAValid(this.item.getYMId());
        },
        isMaveConnected() {
            //TO-DO Реализовать метод тут
            return true;
        },
    },
    mounted() {
        this.$root.$on('bv::modal::hide', () => {
            if (this.errorVKAds) {
                this.errorVKAds = '';
                this.vkAds.id = null;
            }

            if (this.errorVK) {
                this.errorVK = '';
                this.vkPixel.id = null;
            }

            if (this.errorYA) {
                this.errorYA = '';
                this.ya.ymId = null;
            }

            if (this.errorGA) {
                this.errorGA = '';
                this.ga.gaId = null;
            }

            if (this.errorFB) {
                this.errorFB = '';
                this.clearFBPixel();
            }

            if (this.errorMave) {
                this.errorMave = '';
                this.mave.id = null;
            }

            this.setForm(this.item);
        });
    },
    methods: {
        ...mapActions({
            edit: 'project/edit',
            checkFB: 'project/testFBPixel',
            checkYA: 'project/testYAMetric',
            connectMave: 'project/connectMave',
            disconnectMave: 'project/disconnectMave',
        }),
        actionRemove() {
            if (!this.removeItem) return;

            if (this.removeItem.type == 'YA') {
                this.ya.ymId = null;
                this.saveYA();
            }

            if (this.removeItem.type == 'GA') {
                this.ga.gaId = null;
                this.saveGA();
            }

            if (this.removeItem.type == 'VKAds') {
                this.vkAds.id = null;
                this.saveVKAds();
            }

            if (this.removeItem.type == 'VK') {
                this.vkPixel.id = null;
                this.saveVK();
            }

            if (this.removeItem.type == 'FB') {
                this.clearFBPixel();
                this.saveFB();
            }

            if (this.removeItem.type == 'Mave') {
                this.removeIntegrationMave();
            }

            if (this.removeItem.type != 'Mave') {
                this.$bvModal.hide('remove-integration');
            }
        },
        saveVKAds() {
            _.each(this.vkAds, (p, i, l) => (l[i] = p == '' ? null : p == null ? p : p.trim()));

            this.edit({ vkAds: this.vkAds.id, id: this.item.id })
                .then(v => {
                    this.$store.commit('project/editAnalytics', { vkAds: this.vkAds.id });
                    this.$notify(
                        'success',
                        this.$t(this.vkAds.id == null ? 'success.removeIntegration' : 'success.addedInegration')
                    );
                    this.$bvModal.hide('integration-form-vk-ads');
                })
                .catch(er => this.$notify('error', this.$t('project.analytics.titleEdit'), er));
        },
        saveVK() {
            _.each(this.vkPixel, (p, i, l) => (l[i] = p == '' ? null : p == null ? p : p.trim()));

            this.edit({ vkPixel: this.vkPixel.id, id: this.item.id })
                .then(v => {
                    this.$store.commit('project/editAnalytics', { vkPixel: this.vkPixel.id });
                    this.$notify(
                        'success',
                        this.$t(this.vkPixel.id == null ? 'success.removeIntegration' : 'success.addedInegration')
                    );
                    this.$bvModal.hide('integration-form-vk');
                })
                .catch(er => this.$notify('error', this.$t('project.analytics.titleEdit'), er));
        },
        saveGA() {
            _.each(this.ga, (p, i, l) => (l[i] = p == '' ? null : p == null ? p : p.trim()));

            this.edit({ googleAnalytics: this.ga, id: this.item.id })
                .then(v => {
                    this.$store.commit('project/editAnalytics', { ga: _.cloneDeep(this.ga) });
                    this.$notify(
                        'success',
                        this.$t(this.ga.gaId == null ? 'success.removeIntegration' : 'success.addedInegration')
                    );
                    this.$bvModal.hide('integration-form-ga');
                })
                .catch(er => this.$notify('error', this.$t('project.analytics.titleEdit'), er));
        },
        saveYA() {
            _.each(this.ya, (p, i, l) => (l[i] = p == '' ? null : p == null ? p : p.trim()));

            this.edit({ yandexMetrics: this.ya, id: this.item.id })
                .then(v => {
                    this.$store.commit('project/editAnalytics', { ya: _.cloneDeep(this.ya) });
                    this.$notify(
                        'success',
                        this.$t(this.ya.ymId == null ? 'success.removeIntegration' : 'success.addedInegration')
                    );
                    this.$bvModal.hide('integration-form-ya');
                })
                .catch(er => this.$notify('error', this.$t('project.analytics.titleEdit'), er));
        },
        saveFB() {
            _.each(this.pixel, (p, i, l) => (l[i] = p == '' ? null : p == null ? p : p.trim()));

            this.edit({ facebookPixel: this.pixel, id: this.item.id })
                .then(v => {
                    this.$store.commit('project/editAnalytics', { pixel: _.cloneDeep(this.pixel) });
                    this.$notify(
                        'success',
                        this.$t(this.pixel.pixelId == null ? 'success.removeIntegration' : 'success.addedInegration')
                    );
                    this.$bvModal.hide('integration-form-facebook');
                })
                .catch(er => this.$notify('error', this.$t('project.analytics.titleEdit'), er));
        },
        saveMave() {
            this.isLoading = true;

            this.connectMave({
                id: this.item.id,
                key: this.mave.id,
            })
                .then(v => {
                    this.$notify('success', this.$t('success.addedInegration'));
                    this.hasMave = true;
                    this.$bvModal.hide('integration-form-mave');
                    this.isLoading = false;
                })
                .catch(error => {
                    this.errorMave = this.$t('errors.maveId');
                    this.isLoading = false;
                });
        },

        removeIntegrationMave() {
            this.disconnectMave({
                id: this.item.id,
            }).then(v => {
                this.mave.id = null;
                this.hasMave = false;
                this.$notify('success', this.$t('success.removeIntegration'));
                this.$bvModal.hide('remove-integration');
            });
        },
        setForm(nv) {
            this.pixel.pixelId = nv.pixel.getId();
            this.pixel.viewProject = nv.pixel.getViewProject();
            this.pixel.clickPurchase = nv.pixel.getClickPurchase();
            this.pixel.purchase = nv.pixel.getPurchase();
            this.ga.gaId = nv.getGAId();
            this.ya.ymId = nv.getYMId();
            this.vkAds.id = nv.getVKAdsId();
            this.vkPixel.id = nv.getVKPixelId();
            this.hasFBPixel = this.pixel && this.pixel.pixelId;
        },

        clearFBPixel() {
            this.pixel.pixelId = null;
            this.pixel.viewProject = null;
            this.pixel.clickPurchase = null;
            this.pixel.purchase = null;
            this.hasFBPixel = false;
        },
        isVKAdsValid() {
            this.errorVKAds = Project.isVKAdsValid(this.vkAds.id) ? '' : this.$t('errors.VKAdsId');
        },
        isVKValid() {
            this.errorVK = Project.isVKPixelValid(this.vkPixel.id) ? '' : this.$t('errors.VKpixelId');
        },
        isGAValid() {
            this.errorGA = Project.isGAValid(this.ga.gaId) ? '' : this.$t('errors.GAMetricId');
        },
        isFBValid() {
            this.errorFB = Project.isFBValid(this.pixel.pixelId) ? '' : this.$t('errors.pixelId');
        },
        isYAValid() {
            this.errorYA = Project.isYAValid(this.ya.ymId) ? '' : this.$t('errors.metricId');
        },
        isMaveValid() {
            this.errorMave = Project.isMaveValid(this.mave.id) ? '' : this.$t('errors.maveId');
        },
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`);
            } catch (e) {
                console.log(e);
            }
        },

        openModalRemove(type, item) {
            this.removeItem = _.cloneDeep({ type: type });
        },
    },
    watch: {
        item(nv) {
            if (nv != null) {
                this.setForm(nv);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.integration {
    &__card {
        width: 49%;
        height: 100px;
        padding: 27px 45px;
        border-radius: var(--border-radius-rounded);
        box-shadow: var(--box-shadow-dark-none);
        background-color: var(--foreground-color);
        transition: var(--animation-time-short);
        overflow: hidden;

        &:hover,
        &:active {
            box-shadow: var(--box-shadow-hover);
        }

        @include respond-below(sm) {
            width: 100%;
        }

        &.success {
            position: relative;
            order: -1;
            padding: 38px 45px 27px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 24px;
                background-color: var(--brand-bg-success);
            }
        }

        &.error {
            position: relative;
            padding: 38px 45px 27px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 24px;
                background-color: var(--brand-bg-error);
            }
        }
    }

    &__text {
        position: absolute;
        top: 3px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__plus-icon {
        width: 35px;
        max-width: 35px;
        min-width: 35px;
        height: 35px;
        max-height: 35px;
        min-width: 35px;
        padding: 8px;
        box-shadow: none !important;

        &:hover,
        &:active {
            background-color: var(--brand-primary) !important;
            box-shadow: none !important;
        }
    }

    &__edit-icon {
        width: 35px;
        max-width: 35px;
        min-width: 35px;
        height: 35px;
        max-height: 35px;
        min-width: 35px;
        padding: 8px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--primary-bg-color);

        i {
            font-size: 16px !important;
            color: var(--brand-primary);
        }
    }

    &__base-link {
        max-width: 228px;

        &.is-mave {
            max-width: max-content;
            margin-bottom: -1.5px;

            @include respond-below(sm-new) {
                max-width: 100%;
                margin-bottom: 21px;
            }
        }
    }

    &__warning {
        padding: 16px 16px 16px 51px;
        background-color: var(--brand-bg-warning);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 16px;
            width: 24px;
            height: 24px;
            background: url('/assets/img/icons/info-square.svg');
            background-repeat: no-repeat;
        }

        @include respond-below(sm) {
            padding: 16px 21px 16px 51px;
        }
    }

    &__info {
        padding: 19px;
        background-color: var(--primary-bg-color);
        border-radius: var(--border-radius-rounded);

        @include respond-below(sm-new) {
            padding: 15px 17px;
        }
    }

    &__error {
        padding: 16px 16px 16px 51px;
        background-color: var(--brand-bg-error);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 16px;
            width: 24px;
            height: 24px;
            mask-image: url('/assets/img/icons/info-square.svg');
            background-color: var(--brand-error);
            background-repeat: no-repeat;
        }

        @include respond-below(sm) {
            padding: 16px 21px 16px 51px;
        }
    }

    &__card-link {
        padding: 15px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--bg-back);
    }

    &__modal-image {
        @include respond-below(sm-new) {
            max-width: 172px;
            max-height: 27px;

            &.is-mave {
                max-height: 30.5px;
                margin-bottom: 19px;
            }
        }
    }

    &__remove-icon {
        width: 35px;
        height: 35px;
        font-size: 16px !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
